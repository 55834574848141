import { Intent } from "@blueprintjs/core";
import moment from "moment";
import React, { useMemo } from "react";

import { HexTag } from "../../hex-components/HexTag.js";
import { HexTooltip } from "../../hex-components/HexTooltip.js";

import { HumanDate } from "./HumanDate.js";

export const HardDeleteWarningTag = React.memo<{
  dateTrashed: string | null;
  isComponent: boolean;
  className?: string | undefined;
}>(function HardDeleteWarningTag({ className, dateTrashed, isComponent }) {
  const { hardDeleteDate, hardDeleteDateHasPassed, isoString } = useMemo(() => {
    if (dateTrashed == null) {
      return { hardDeleteDate: null, isoString: null };
    }

    // Projects are hard deleted 7 days after they're trashed
    // via a background job.
    const targetDeleteDate = moment(dateTrashed).add(7, "days");
    const now = moment();

    // If the target delete date has passed but the project hasn't been
    // hard deleted yet, that likely means something went wrong with the
    // deletion job. In that case, we'll just claim that the project
    // will be deleted today to avoid weird UI.

    return {
      hardDeleteDateHasPassed: targetDeleteDate.isBefore(now),
      hardDeleteDate: targetDeleteDate.fromNow(),
      isoString: targetDeleteDate.toISOString(),
    };
  }, [dateTrashed]);

  return hardDeleteDate == null ? null : (
    <HexTooltip
      className={className}
      content={`This ${
        isComponent ? "component" : "project"
      } will be permanently deleted ${
        hardDeleteDateHasPassed ? "today" : hardDeleteDate
      }`}
      hoverOpenDelay={400}
      placement="bottom"
    >
      <HexTag intent={Intent.DANGER}>
        {hardDeleteDateHasPassed ? (
          "Today"
        ) : (
          <HumanDate date={isoString} title={false} />
        )}
      </HexTag>
    </HexTooltip>
  );
});
