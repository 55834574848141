import { gql } from "@apollo/client";
import { ItemListRenderer, ItemRenderer } from "@blueprintjs/select";
import {
  IntrinsicGroupId,
  filterUsingDiscriminate,
  guardNever,
} from "@hex/common";
import React, { ReactNode, useCallback } from "react";
import styled from "styled-components";

import { HexMenu, HexMenuItem } from "../../hex-components/HexMenuItem.js";
import {
  HexSpinner,
  HexSpinnerSizes,
} from "../../hex-components/HexSpinner.js";
import { GroupAvatar } from "../user/GroupAvatar.js";
import { IntrinsicGroupAvatar } from "../user/IntrinsicGroupAvatar.js";
import { UserAvatar } from "../user/UserAvatar.js";

import {
  GroupSelectFragment,
  GroupSelectRenderingFragment,
  IntrinsicGroupSelectFragment,
  IntrinsicGroupSelectRenderingFragment,
  UserSelectFragment,
  UserSelectRenderingFragment,
} from "./UserGroupSelect2.generated.js";

/**
 * Rather than directly provide a component to use, this file instead exports
 * a set of utilities that allow one to more easily create their own user group
 * select using blueprint components.
 */

/** Shared types */

gql`
  fragment UserSelectFragment on User {
    userId: id
  }

  fragment GroupSelectFragment on Group {
    groupId: id
  }

  fragment IntrinsicGroupSelectFragment on IntrinsicGroup {
    intrinsicGroupId: id
  }
`;

export type UserGroupSelectItem =
  | UserSelectFragment
  | GroupSelectFragment
  | IntrinsicGroupSelectFragment;

/** Item renderer */

gql`
  fragment UserSelectRenderingFragment on User {
    ...UserSelectFragment
    userName: name
    email
    active
    imageUrl
    orgRole
  }

  fragment GroupSelectRenderingFragment on Group {
    ...GroupSelectFragment
    groupName: name
    userGroupLinks(includeDeactivated: false) {
      id
    }
  }

  fragment IntrinsicGroupSelectRenderingFragment on IntrinsicGroup {
    ...IntrinsicGroupSelectFragment
    intrinsicGroupName: name
    userCount
  }
`;

export type UserGroupSelectRenderingItem =
  | UserSelectRenderingFragment
  | GroupSelectRenderingFragment
  | IntrinsicGroupSelectRenderingFragment;

export interface UseUserGroupSelectItemRendererArgs<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- placeholder
  T extends UserGroupSelectRenderingItem,
> {}

export function useUserGroupSelectItemRenderer<
  T extends UserGroupSelectRenderingItem,
>(_args: UseUserGroupSelectItemRendererArgs<T>): ItemRenderer<T> {
  return useCallback((item, { handleClick, modifiers }) => {
    if (item.__typename === "User") {
      return (
        <HexMenuItem
          key={item.userId}
          active={modifiers.active}
          disabled={modifiers.disabled}
          icon={
            <UserAvatar
              active={item.active}
              email={item.email}
              imageUrl={item.imageUrl ?? undefined}
              name={item.userName ?? undefined}
              role={item.orgRole}
              size={20}
            />
          }
          label={item.userName != null ? item.email : undefined}
          text={item.userName || item.email}
          onClick={handleClick}
        />
      );
    } else if (item.__typename === "Group") {
      return (
        <HexMenuItem
          key={item.groupId}
          active={modifiers.active}
          disabled={modifiers.disabled}
          icon={
            <GroupAvatar
              name={item.groupName}
              userCount={item.userGroupLinks.length}
            />
          }
          label={`${item.userGroupLinks.length} user${
            item.userGroupLinks.length === 1 ? "" : "s"
          }`}
          text={item.groupName}
          onClick={handleClick}
        />
      );
    } else if (item.__typename === "IntrinsicGroup") {
      return (
        <HexMenuItem
          key={item.intrinsicGroupId}
          active={modifiers.active}
          disabled={modifiers.disabled}
          icon={
            <IntrinsicGroupAvatar
              id={item.intrinsicGroupId}
              name={item.intrinsicGroupName}
              userCount={
                item.intrinsicGroupId !== IntrinsicGroupId.PUBLIC
                  ? item.userCount
                  : undefined
              }
            />
          }
          label={
            item.intrinsicGroupId !== IntrinsicGroupId.PUBLIC
              ? `${item.userCount} user${item.userCount === 1 ? "" : "s"}`
              : undefined
          }
          text={item.intrinsicGroupName}
          onClick={handleClick}
        />
      );
    } else {
      guardNever(item, (item as { __typename: string }).__typename);
      return null;
    }
  }, []);
}

/** List renderer */

export interface UserGroupSelectSection<T extends UserGroupSelectItem> {
  key: string;
  name?: string;
  items: readonly T[];
}

export interface UseUserGroupSelectListRendererArgs<
  T extends UserGroupSelectItem,
> {
  sectionCreator?: (items: T[]) => UserGroupSelectSection<T>[];
  loading?: boolean;
  error?: boolean;
  topContents?: ReactNode;
}

function defaultSectionCreator<T extends UserGroupSelectItem>(
  items: readonly T[],
): UserGroupSelectSection<T>[] {
  const groupsToRender = filterUsingDiscriminate(items, "__typename", "Group");
  const usersToRender = filterUsingDiscriminate(items, "__typename", "User");
  const intrinsicGroupsToRender = filterUsingDiscriminate(
    items,
    "__typename",
    "IntrinsicGroup",
  );

  const hasGroups = groupsToRender.length + intrinsicGroupsToRender.length > 0;
  const hasUsers = usersToRender.length > 0;

  if (hasGroups && hasUsers) {
    return [
      {
        key: "groups",
        name: "Groups",
        items: [...intrinsicGroupsToRender, ...groupsToRender],
      },
      {
        key: "users",
        name: "Users",
        items: usersToRender,
      },
    ];
  } else {
    return [
      {
        key: "all",
        items,
      },
    ];
  }
}

export function useUserGroupSelectListRenderer<T extends UserGroupSelectItem>({
  error,
  loading,
  sectionCreator = defaultSectionCreator,
  topContents,
}: UseUserGroupSelectListRendererArgs<T>): ItemListRenderer<T> {
  return useCallback(
    ({ filteredItems, itemsParentRef, menuProps, renderItem }) => {
      const sections = sectionCreator(filteredItems);

      let currentIdx = 0;

      return (
        <HexMenu ulRef={itemsParentRef} {...menuProps}>
          {topContents}
          {loading ? (
            <HexMenuItem
              disabled={true}
              text={<HexSpinner size={HexSpinnerSizes.STANDARD} />}
            />
          ) : error ? (
            <HexMenuItem disabled={true} text="Search Failed." />
          ) : filteredItems.length === 0 ? (
            <HexMenuItem disabled={true} text="No results" />
          ) : (
            sections.map(({ items, key, name }) => (
              <React.Fragment key={key}>
                {name != null && (
                  <SearchResultGroupLabel>{name}</SearchResultGroupLabel>
                )}
                {items.map((item) => renderItem(item, currentIdx++))}
              </React.Fragment>
            ))
          )}
        </HexMenu>
      );
    },
    [error, loading, sectionCreator, topContents],
  );
}

const SearchResultGroupLabel = styled.div<{ $spaceAbove?: boolean }>`
  display: flex;
  gap: 4px;
  align-items: center;
  margin: 6px 0px 7px 7px;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-weight: ${({ theme }) => theme.fontWeight.SEMI_BOLD};
  font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};
  text-transform: uppercase;

  ${({ $spaceAbove }) => $spaceAbove && `margin-top: 12px`}
`;
