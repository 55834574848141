import { Classes } from "@blueprintjs/core";
import { Classes as Popover2Classes } from "@blueprintjs/popover2";
import React from "react";
import styled, { css } from "styled-components";

import { BaseHexCard } from "../../../hex-components/HexCard.js";
import ScreenshotPlaceholderImg from "../../../static/illustration/illustration-screenshot-placeholder.inline.svg";
import { HardDeleteWarningTag } from "../HardDeleteWarningTag.js";
import { ShowOnHover, ShowOnHoverContainer } from "../ShowOnHover.js";

// ==== Project card building blocks ====
export const RightActionArea = styled(ShowOnHover)<{
  $minimalProjectCard: boolean;
}>`
  position: ${({ $minimalProjectCard }) =>
    $minimalProjectCard ? "static" : "absolute"};
  top: 0;
  right: 0;
  display: flex;
  align-self: ${({ $minimalProjectCard }) =>
    $minimalProjectCard ? "flex-start" : undefined};
  gap: 4px;
  padding: ${({ $minimalProjectCard }) =>
    $minimalProjectCard ? "8px 12px 8px 0" : "4px 4px 0 0"};

  .${Classes.BUTTON} {
    &:hover {
      /** !important is required to override the default button hover color */
      background-color: ${({ theme }) =>
        theme.NonTransparentHoverColor} !important;
    }
  }
`;

const ImageStyles = css`
  max-width: 100%;
  min-height: 100%;
  border-radius: inherit;
  width: auto;
  height: auto;
  object-fit: cover;
  object-position: 0 50%;
  position: absolute;
  left: 0;
`;

const ScreenshotPlaceholder = styled(ScreenshotPlaceholderImg)`
  ${ImageStyles}
  color: ${({ theme }) => theme.borderColor.MUTED};
  width: 100%;
  top: 0;
`;

export const CardImg = styled.img`
  ${ImageStyles}
  /* Offset the image so it displays project contents, not just the title
  (since project screenshots usually have the title at the top) */
  top: -50px;
`;

export const CardImgOverlay = styled.span`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: ${({ theme }) =>
    theme.type === "LIGHT" ? "black" : "white"};
  transition: opacity ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};
`;

export const CardImageWithOverlay: React.ComponentType<{
  src?: string | null;
  usePlaceholder: boolean;
}> = ({ src, usePlaceholder }) => {
  if (src == null && !usePlaceholder) {
    return null;
  }

  const Img =
    src != null ? (
      <CardImg alt="Project preview" loading="lazy" src={src} />
    ) : (
      <ScreenshotPlaceholder />
    );

  return (
    <>
      {Img}
      <CardImgOverlay />
    </>
  );
};

export const CardMedia = styled.div`
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  padding: 0.1em;

  :hover {
    ${CardImgOverlay} {
      opacity: 0.05;
    }
  }
`;

export const RightActionWrapper = styled.div<{ $hasScreenshot: boolean }>`
  flex: initial;
  display: flex;
  overflow: hidden;

  ${({ $hasScreenshot }) =>
    $hasScreenshot
      ? css`
          position: relative;
          min-width: 38%;
          margin: 8px 8px 0 0;
          border-top: 1px solid ${({ theme }) => theme.borderColor.MUTED};
          border-right: 1px solid ${({ theme }) => theme.borderColor.MUTED};
          border-left: 1px solid ${({ theme }) => theme.borderColor.MUTED};
          border-radius: ${({ theme }) => theme.borderRadius}
            ${({ theme }) => theme.borderRadius} 0 0;
        `
      : css`
          min-width: 0;
        `}
`;

export const CardContent = styled.div<{ $minimalProjectCard: boolean }>`
  padding: ${({ $minimalProjectCard }) =>
    $minimalProjectCard ? "8px 0 8px 12px" : " 12px 12px 10px 14px"};
  display: flex;
  /* Important: this row-gap is coupled with -1px top margin set on the minimal CardHeader */
  row-gap: ${({ $minimalProjectCard }) =>
    $minimalProjectCard ? "2px" : undefined};
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex: 1 0 0;
  min-width: 0;
`;

export const CardTitleDescription = styled.div<{
  $minimalProjectCard: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: ${({ $minimalProjectCard }) =>
    $minimalProjectCard ? "-2px" : undefined};
`;

const getLineClampStyles = (maxLines = 2) => css`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: ${maxLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: ${maxLines === 1 ? "break-all" : undefined};
`;

export const CardDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  color: ${({ theme }) => theme.fontColor.MUTED};
  line-height: 1.4;
  ${getLineClampStyles(2)}
  margin-bottom: 4px;
`;

export const CardHeader = styled.div<{
  isPublished?: boolean;
  $minimalProjectCard: boolean;
}>`
  display: flex;
  align-items: center;
  /* Important: this margin-top is coupled with 2px row-gap on minimal CardContent */
  margin-top: ${({ $minimalProjectCard }) =>
    $minimalProjectCard ? "-1px" : undefined};

  /* Note: Unpublished cards need this calculated max width to prevent
  the card title from covering the action controls fixed to the top right;
  Specifically, this prevents overlap with the "more actions" button
  28px is our default button width; */
  ${({ isPublished = false }) =>
    !isPublished
      ? `
        max-width: calc(100% - 28px);
        width: 100%;
      `
      : null}
`;

export const CardHeading = styled.h1<{ $twoLineHeading?: boolean }>`
  all: unset;
  font-size: 15px;
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  /* Note: We want the line height to be as close to 20px as we can, and this seems like a magic ratio */
  line-height: 1.3334;
  /* Note: This was added to to accommodate the muted header for disabled cards. */
  color: inherit;

  ${({ $twoLineHeading }) =>
    $twoLineHeading ? getLineClampStyles(2) : getLineClampStyles(1)};
`;

export const COLLECTION_CARD_HEIGHT = 114;
export const DEFAULT_CARD_HEIGHT = 120;
export const MINIMAL_CARD_HEIGHT = 54;
// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
const BaseProjectCard = styled(BaseHexCard).attrs({ as: "article" })<{
  $minimal?: boolean;
  $height: number;
  $selected?: boolean;
}>`
  /* HexCard/HexBaseCard has 20px of default padding, so we're overriding it here */
  padding: 0;
  position: relative;
  color: ${({ theme }) => theme.fontColor.DEFAULT};
  display: flex;
  flex: auto;
  justify-content: space-between;
  height: ${({ $height = COLLECTION_CARD_HEIGHT }) => $height}px;

  transition: all ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};

  &&& {
    ${({ $selected, theme }) =>
      $selected && `box-shadow${theme.boxShadow.FOCUS_PRIMARY};`}
  }
`;

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
export const InteractiveCard = styled(BaseProjectCard).attrs({
  interactive: true,
  elevation: "flat",
})`
  :hover,
  :focus-within {
    ${CardMedia} ${CardImgOverlay} {
      opacity: 0.04;
    }
  }
`;

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
export const ReadonlyCard = styled(BaseProjectCard).attrs({
  interactive: false,
  elevation: "flat",
})`
  color: ${({ theme }) => theme.fontColor.MUTED};
  background-color: ${({ theme }) => theme.backgroundColor.MUTED};
`;

export const CardMetadata = styled.div`
  display: flex;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 1.4;
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

export const CardHeadingAnchor = styled.a`
  color: inherit;

  :hover {
    color: inherit;
  }
`;

export const FlexShowOnHoverContainer = styled(ShowOnHoverContainer)`
  display: flex;
  flex: auto;
`;

export const CardSubHeading = styled.div`
  color: inherit;
  display: flex;
  column-gap: 4px;
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  margin-bottom: auto;
  margin-left: 30px;
`;

export const CardHardDeleteWarningTag = styled(HardDeleteWarningTag)`
  &&.${Popover2Classes.POPOVER2_TARGET} {
    padding-left: 5px;
  }
`;
