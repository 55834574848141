import {
  DefaultPopover2TargetHTMLProps,
  Placement,
  Popover2TargetProps,
} from "@blueprintjs/popover2";
import React, { useCallback } from "react";
import styled from "styled-components";

import { HexTooltip } from "../../hex-components/HexTooltip.js";
import { FilledStarIcon } from "../icons/CustomIcons";

const DEFAULT_UNSTARRED_LABEL = "Add to favorites";
const DEFAULT_STARRED_LABEL = "Remove from favorites";
export interface StarIconCheckboxProps {
  id: HTMLInputElement["id"];
  onChange?: (starred: boolean) => void;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  starred?: HTMLInputElement["checked"];
  disabled?: HTMLInputElement["disabled"];
  className?: string;
  tooltipPlacement?: Placement;
}

const StarIconCheckboxInternal: React.FunctionComponent<
  StarIconCheckboxProps
> = ({
  className,
  disabled = false,
  id,
  onChange,
  onClick,
  starred,
  tooltipPlacement,
}) => {
  const handleClick = React.useCallback(
    (e) => {
      e.stopPropagation();
      onClick?.(e);
    },
    [onClick],
  );
  const handleChange = React.useCallback(
    (e) => {
      onChange?.(e.target.checked);
    },
    [onChange],
  );
  const label = starred ? DEFAULT_STARRED_LABEL : DEFAULT_UNSTARRED_LABEL;

  const renderTarget = useCallback(
    ({
      as: _as,
      children: _children,
      ...targetProps
    }: Popover2TargetProps & DefaultPopover2TargetHTMLProps) => {
      return (
        <StyledSpan
          {...targetProps}
          className={className}
          data-checked={starred}
        >
          <StyledInput
            $disabled={disabled}
            aria-label={label}
            checked={starred}
            disabled={disabled}
            id={id}
            type="checkbox"
            value="starred"
            onChange={handleChange}
            onClick={handleClick}
          />
          <FilledStarIcon aria-labelledby={`${id}-svg`} color="currentColor">
            <title id={`${id}-svg`}>{label}</title>
          </FilledStarIcon>
        </StyledSpan>
      );
    },
    [className, disabled, handleChange, handleClick, id, label, starred],
  );

  return (
    <HexTooltip
      content={label}
      placement={tooltipPlacement}
      renderTarget={renderTarget}
    />
  );
};

export const StarIconCheckbox = styled(StarIconCheckboxInternal)``;

const StyledSpan = styled.span`
  & {
    position: relative;
    display: inline-flex;

    color: ${({ theme }) => theme.iconColor};

    &[data-checked="true"] {
      color: ${({ theme }) => theme.userActionColor};
      .bp4-icon {
        color: inherit;
      }
      &&&&&&&&&&& span svg {
        color: ${({ theme }) => theme.userActionColor};
      }
    }
  }
`;

const StyledInput = styled.input<{ $disabled?: boolean }>`
  &[type="checkbox"] {
    display: inline-flex;
    border-radius: ${({ theme }) => theme.borderRadius};

    top: 0;
    left: 0;
    position: absolute;

    &:focus-visible,
    &:focus-within,
    &:focus {
      outline-width: 1.5px;
      outline-offset: 0.5px;
    }

    cursor: pointer;
    border: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    appearance: none;

    background: transparent;

    & ~ span {
      border-radius: ${({ theme }) => theme.borderRadius};
      padding: 2px;
      svg > path {
        transition: fill-opacity ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing};
        transition: all ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing};
        fill-opacity: 0;
      }
    }

    &:not(:checked) ~ span {
      svg path:first-of-type {
        fill-opacity: 0;
      }
    }

    &:hover:not(:disabled) ~ span {
      svg path:first-of-type {
        fill-opacity: 0.35;
      }
    }

    &:active:not(:disabled) ~ span {
      svg path:first-of-type {
        fill-opacity: 1;
      }
    }
  }
`;
