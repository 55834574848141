import {
  Classes,
  IToastOptions,
  IToasterProps,
  Position,
  ToastProps,
  Toaster,
  ToasterInstance,
  ToasterPosition,
} from "@blueprintjs/core";
import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";

/**
 * Apply this class to the `className` option of `toaster.show()`
 * in order to opt out of most of our default toast styles in `GlobalStyle.ts`.
 * This is useful when making custom toasts with fancier UIs
 * since many of default styles are implemented with !important and hard to override.
 */
export const HEX_CUSTOM_TOAST_CLASS = "hex-custom-toast";

export const HEX_CUSTOM_BACKGROUND_REFRESH_TOAST_CLASS =
  "hex-background-refresh-toast";

export const ToastIcon = styled.div`
  padding: 0 0 0 15px;

  font-size: 24px;

  /*
  This hides the default close button (which doesn't seem disable-able) in any other way.
  I needed to center the button vertically and decided the only way to do that was either use the
  <Toast /> component and wrap that in css, or do this.
  */
  & ~ .${Classes.BUTTON_GROUP} {
    display: none;
  }
`;

export const ToastBody = styled.div`
  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
`;

export const ToastContent = styled.div`
  margin-right: 50px;
`;

export const ToastContentTitle = styled.div`
  margin-bottom: 2px;

  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
`;

export const ToastContentDescription = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

export interface HexToastProps extends ToastProps {
  /**
   * Where to show the given toast
   *
   * @default Position.TOP
   */
  position?: ToasterPosition;
}

export interface HexToasterInstance extends ToasterInstance {
  show: (props: HexToastProps, key?: string) => string;
}

export class HexToaster implements HexToasterInstance {
  constructor(
    private toasters: {
      [K in ToasterPosition]: ToasterInstance;
    },
  ) {}

  public show: HexToasterInstance["show"] = (
    { position = Position.TOP, ...props },
    key,
  ) => {
    return this.toasters[position].show(props, key);
  };

  public dismiss: HexToasterInstance["dismiss"] = (...args) => {
    for (const toaster of Object.values(this.toasters)) {
      toaster.dismiss(...args);
    }
  };

  public clear: HexToasterInstance["clear"] = (...args) => {
    for (const toaster of Object.values(this.toasters)) {
      toaster.clear(...args);
    }
  };

  public getToasts: HexToasterInstance["getToasts"] = (...args) => {
    const options: IToastOptions[] = [];
    for (const toaster of Object.values(this.toasters)) {
      options.push(...toaster.getToasts(...args));
    }
    return options;
  };
}

/* eslint-disable tree-shaking/no-side-effects-in-initialization */
const DefaultToaster = new HexToaster({
  [Position.TOP_LEFT]: Toaster.create({ position: Position.TOP_LEFT }),
  [Position.TOP]: Toaster.create({ position: Position.TOP }),
  [Position.TOP_RIGHT]: Toaster.create({ position: Position.TOP_RIGHT }),
  [Position.BOTTOM_RIGHT]: Toaster.create({ position: Position.BOTTOM_RIGHT }),
  [Position.BOTTOM]: Toaster.create({ position: Position.BOTTOM }),
  [Position.BOTTOM_LEFT]: Toaster.create({ position: Position.BOTTOM_LEFT }),
});
/* eslint-enable tree-shaking/no-side-effects-in-initialization */

const ToasterContext = createContext<HexToaster>(DefaultToaster);

export function useToaster(): HexToaster {
  return useContext(ToasterContext);
}

export interface ToasterProviderProps extends Omit<IToasterProps, "position"> {
  children?: ReactNode;
}

export const ToasterProvider: React.ComponentType<ToasterProviderProps> =
  React.memo(function ToasterProvider({ children, ...toasterProps }) {
    const [topLeftToaster, setTopLeftToaster] =
      useState<ToasterInstance | null>(null);
    const [topToaster, setTopToaster] = useState<ToasterInstance | null>(null);
    const [topRightToaster, setTopRightToaster] =
      useState<ToasterInstance | null>(null);
    const [bottomLeftToaster, setBottomLeftToaster] =
      useState<ToasterInstance | null>(null);
    const [bottomToaster, setBottomToaster] = useState<ToasterInstance | null>(
      null,
    );
    const [bottomRightToaster, setBottomRightToaster] =
      useState<ToasterInstance | null>(null);

    const toaster = useMemo(
      () =>
        topLeftToaster &&
        topToaster &&
        topRightToaster &&
        bottomLeftToaster &&
        bottomToaster &&
        bottomRightToaster
          ? new HexToaster({
              [Position.TOP_LEFT]: topLeftToaster,
              [Position.TOP]: topToaster,
              [Position.TOP_RIGHT]: topRightToaster,
              [Position.BOTTOM_LEFT]: bottomLeftToaster,
              [Position.BOTTOM]: bottomToaster,
              [Position.BOTTOM_RIGHT]: bottomRightToaster,
            })
          : undefined,
      [
        bottomLeftToaster,
        bottomRightToaster,
        bottomToaster,
        topLeftToaster,
        topRightToaster,
        topToaster,
      ],
    );

    return (
      <ToasterContext.Provider value={toaster ?? DefaultToaster}>
        <Toaster
          {...toasterProps}
          ref={setTopLeftToaster}
          position={Position.TOP_LEFT}
        />
        <Toaster
          {...toasterProps}
          ref={setTopToaster}
          position={Position.TOP}
        />
        <Toaster
          {...toasterProps}
          ref={setTopRightToaster}
          position={Position.TOP_RIGHT}
        />
        <Toaster
          {...toasterProps}
          ref={setBottomLeftToaster}
          position={Position.BOTTOM_LEFT}
        />
        <Toaster
          {...toasterProps}
          ref={setBottomToaster}
          position={Position.BOTTOM}
        />
        <Toaster
          {...toasterProps}
          ref={setBottomRightToaster}
          position={Position.BOTTOM_RIGHT}
        />
        {children}
      </ToasterContext.Provider>
    );
  });
